import React, { useContext, useState, useRef, useEffect } from 'react';
import Axios from 'axios';
import { AppContext } from '../contexts/AppContext';
import Pulse from '../components/Fragments/Pulse/Pulse';
import Channel from '../components/Fragments/Channel/Channel';
import TopTraders from '../components/Fragments/TopTraders';
import GlobalNews from '../components/Fragments/GlobalNews';
import ManagedFunds from '../components/Fragments/ManagedFunds';
import VIP from '../components/Fragments/VIP';
import Calender from '../components/Calender';
import TradingFloorSelector from '../components/TradingFloorSelector';
import ClassroomNav from '../components/ClassroomNav';

const chatUrl = 'https://chat.gxnitrous.com/gxchat/';

const navLinks = [
  { title: 'Overview', icon: 'fa fa-bookmark', link: '#', enabled: true },
  { title: 'Stream', icon: 'fa fa-video-camera', link: '#', enabled: true },
  { title: 'Channels', icon: 'fa fa-television', link: '#', enabled: true },
  {
    title: 'Leaderboard',
    icon: 'fa fa-trophy',
    link: '#',
    enabled: true
  },
  { title: 'News', icon: 'fa fa-newspaper-o', link: '#', enabled: true },
  {
    title: 'VIP',
    icon: 'fa fa-certificate',
    link: '#',
    enabled: true
  },
  {
    title: 'Marketplace',
    icon: 'fa fa-money',
    link: '#',
    enabled: true
  },
  {
    title: 'Calender',
    icon: 'fa fa-calendar-check-o',
    link: '#',
    enabled: true
  }
];

const ClassRoom = ({ mainData, closeMain, openMain }) => {
  const { tradingFloorView, setTradingFloorView } = useContext(AppContext);
  const [activeNav, setActiveNav] = useState('Stream');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [navBarHeight, setNavBarHeight] = useState(0);
  const [channelPayload, setChannelPayload] = useState(null);

  // Chat States

  const [registeredFlag, setregisteredFlag] = useState('Spin');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [currentUserObj, setCurrentUserObj] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState('');
  const [directoryId, setDirectoryId] = useState('');
  const [redirect, setRedirect] = useState(false);

  //End of Chat States

  const openChannel = data => {
    setChannelPayload(data);
    setActiveNav('Channels');
  };

  const headerRef = useRef();

  let fragment;

  switch (activeNav) {
    case 'Stream':
      fragment = (
        <Pulse
          navBarHeight={navBarHeight}
          opeFloorId={mainData.id}
          openChannel={openChannel}
        />
      );
      break;
    case 'Channels':
      fragment = (
        <Channel
          navBarHeight={navBarHeight}
          floorId={mainData.id}
          payload={channelPayload}
        />
      );
      break;
    case 'Leaderboard':
      fragment = <TopTraders />;
      break;
    case 'News':
      fragment = <GlobalNews />;
      break;
    case 'VIP':
      fragment = <ManagedFunds />;
      break;
    case 'Marketplace':
      fragment = <VIP navBarHeight={navBarHeight} />;
      break;
    case 'Calender':
      fragment = <Calender navBarHeight={navBarHeight} />;
      break;
    default:
      fragment = (
        <div className="trade-floor-home h-100">
          <div className="empty-container mx-auto pt-5">
            <h4 className="text-center">Coming soon...</h4>
          </div>
        </div>
      );
  }

  const screenResizeListener = () => {
    setIsMobile(window.innerWidth <= 500);
    setNavBarHeight(headerRef.current.clientHeight);
  };

  useEffect(() => {
    screenResizeListener();
    window.addEventListener('resize', screenResizeListener);
    return () => {
      window.removeEventListener('resize', screenResizeListener);
    };
  }, []);

  useEffect(() => {
    console.log();
    // console.log(history1.location.pathname);
    // const email1 = localStorage.getItem("user_account");
    // console.log(email1);
    let tok = localStorage.getItem('token');
    setToken(tok);
    setEmail(localStorage.getItem('user_account'));
    Axios.post(chatUrl + 'get_user', {
      email: localStorage.getItem('user_account'),
      token: tok
    }).then(res => {
      if (res.data.status) {
        console.log('jhbjbweffedf', res.data.payload);

        setCurrentUserObj({
          username: res.data.payload.username,
          timestamp: res.data.payload.timestamp,
          email: res.data.payload.email
        });
        setDirectoryId(res.data.payload.directory_id);

        setCurrentUser(res.data.payload.username);
      }
    });
  }, [localStorage.getItem('floor_name')]);

  return (
    <div className="FixedSideBarWraperSection">
      <div
        id="scroll-wrapper"
        className="rightSidePartWraperSection rightSidePartWraperSectionSecond stats-container"
      >
        <div className="trading-floor-wrapper">
          <div className="container-fluid">
            <div className="row trading-row">
              <div className="class-room-container">
                <div className="fixed-nav" ref={headerRef}>
                  <h1 className="text-left affiliate-broker floor-name">
                    {mainData.name
                      ? mainData.name.split('Trading Floor')[0]
                      : ''}
                    <br />
                    <span>Trading Floor</span>
                  </h1>
                  <TradingFloorSelector
                    callBack={openMain}
                    isMobile={isMobile}
                    active={mainData}
                    tradingFloorMain
                  />
                  <ClassroomNav
                    active={activeNav}
                    setActive={setActiveNav}
                    navLinks={navLinks}
                  />
                </div>
                <div
                  className="fragment-container"
                  style={{ height: `calc(100vh - ${navBarHeight}px)` }}
                >
                  {fragment}
                </div>
              </div>
              <div className="chat-container">
                {currentUser
                  ? {
                      /* <GroupChat
                    currentUserObj={currentUserObj}
                    currentUser={currentUser}
                    directoryId={directoryId}
                  /> */
                    }
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClassRoom;
