import React from "react";

const TopTraders = () => {
  return (
    <div className="trading-awards">
      <h4 className="awards-title">Top Traders</h4>
      <p className="awards-intro">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero enim non
        dolorum porro obcaecati earum, aliquid quae, laborum numquam vitae
        architecto distinctio maxime alias illum labore necessitatibus tempora
        quasi cumque?
      </p>
      <div className="row awards-wrapper">
        <AwardItem />
        <AwardItem />
        <AwardItem />
      </div>
    </div>
  );
};

const AwardItem = () => {
  return (
    <div className="col-md-4 award-item">
      <div className="award-item-container">
        <img src="https://i.pravatar.cc/110" alt="" className="user-avatar" />
        <h5 className="award-title">Broker of the Month</h5>
        <h5 className="award-price">$ 500.00</h5>
        <p className="intro-text">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias culpa,
          repudiandae nemo fugiat blanditiis aliquam.
        </p>
      </div>
    </div>
  );
};

export default TopTraders;
