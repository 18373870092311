import React from 'react';
import CardItem from './CardItem';
import marketplaceList from './marketplaceList';
import MarketPlaceDetails from './MarketPlaceDetails';
import GXLogo from '../../../assets/images/gxok.png';

const data = {
  image: GXLogo,
  title: 'GXBroker Total License',
  application: 'BrokerApp',
  intro:
    'Earn commissions on every fiat to crypto trade that your clients perform',
  requirement: '1000 GXT',
  actionName: 'Stake',
  data: {
    type: 'Broker',
    lastUpdated: 'GXBTL1.234.U1.0',
    version: 'GXBTL1.234',
    body: [
      {
        title: 'Nitrous',
        content:
          "Nitrous is a state of the art CRM designed specifically for financial services businesses. You are able to view, manage, and grow your GXBrokerage from one easy to use software. You can see all your clients, their balances, currency volume, contact information, a complete history of trades, and the revenues from each trade. Nitrous' also allows you to run targeted marketing campaigns using GX's world class brand."
      },
      {
        title: 'Dedicated GXBroker Support',
        content:
          "You will have access to a special support team exclusive for GXBroker's. Anytime you need assistance you can reach out via phone or chat 24/7 for multi-lingual support."
      },
      {
        title: 'Unlimited Instantaneous Payouts',
        content:
          'Brokers need liquidity. That is why all GXBrokers get paid instantly for each transaction that they override as well as instant access to monthly and weekly payouts. All payments are paid in GXT so there is no transactional deduction. GXBrokers are also able to request for a bank transfer once the GXT hits their brokerage account. There is no limit to how much a GXBroker can earn as they earn commission for every transactions that all users in their brokerage perform'
      },
      {
        title: 'Diverse & Innovative Products',
        content:
          "Don't be a one trick pony. Having a diversified set of offerings is key to attracting a widespread customer base for you're brokerage. As a GXBroker you get access to the most advanced set of exchange products on the market today ranging from a world class routing engine to a interest bearing decentralized money markets. Learn all about our extensive product offering here."
      },
      {
        title: 'World Class Training',
        content:
          "To ensure the highest level of success for our GXBrokers, we have built the GXAcademy. Here, broker's get access to a fully curated library of compliance, sales, and financial education that can further the success of their brokerage. We also offer weekly training webinars and GXBroker Dealers host Bi-Weekly trainings in their jurisdictions."
      },
      {
        title: 'Aggressive Compensation',
        content:
          'The GXBroker compensation plan is the single most robust and comprehensive offering on the market today. We have taken three of the best compensation structures used in the industry today and combined them to create the GXBroker Matrix.'
      }
    ]
  }
};

const VIP = ({ isMobile, navBarHeight }) => {
  return (
    <div className="container frag-wrapper">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-md-4">
              <div className="row">
                {marketplaceList.map(item => (
                  <CardItem data={item} />
                ))}
              </div>
            </div>
            <MarketPlaceDetails navBarHeight={navBarHeight} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VIP;
