import React from 'react';

const CourseItem = ({ item, onClick }) => {
  console.log('CourseItem =>', item);

  return (
    <div className="course-card" onClick={() => onClick(item)}>
      <div
        className="course-featured-img"
        style={{ backgroundImage: `url(${item.cover_url})` }}
      >
        <label className="course-status">{item.status}</label>
      </div>
      <div className="course-details">
        <div className="course-title-wrapper">
          <h4 className="course-title">{item.name}</h4>
        </div>
        <h6 className="course-auther">{item.author.name}</h6>
      </div>
    </div>
  );
};

export default CourseItem;
