import React from "react";

const RelatedVideoItem = () => {
  return (
    <div className="related-item">
      <div className="thumbnail-wrapper">
        <img
          src="https://picsum.photos/300/200/?blur"
          alt=""
          className="thumbnail"
        />
      </div>
      <div className="item-meta">
        <h4 className="video-title">This is Video Title</h4>
        <h6 className="video-sub-title">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sit dolore
          neque quis fugit deleniti.
        </h6>
      </div>
      <div className="add-to-wrapper">
        <i class="fas fa-plus fa-2x"></i>
      </div>
    </div>
  );
};

export default RelatedVideoItem;
