import React from "react";
import { Button } from "antd";

const PlayerOverview = ({ data }) => {
  return data ? (
    <>
      <div className="course-details-wrapper">
        <div className="course-details">
          <h4 className="video-title">{data.name}</h4>
          <p className="intro-txt">
            Lorem ipsum dolor sit amet consectetur adipisicing elit, Autem ea
            aspernatur.
          </p>
        </div>
      </div>
      <div className="author-wrapper">
        <img
          src={data.author ? data.author.profile_image : ""}
          alt=""
          className="author-img"
        />
        <div className="author-details">
          <label className="author-name">
            {data.author ? data.author.name : "John Doe"}
          </label>
          <label className="trading-floor-name">{"Trading Floor name"}</label>
        </div>
      </div>
      <div className="desc-wrapper">
        <p className="desc">{data.description}</p>
      </div>
    </>
  ) : (
    ""
  );
};

export default PlayerOverview;
