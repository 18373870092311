import React from "react";
import ReactHtmlParser from "react-html-parser";
import mtzon from "moment-timezone";
import { Button } from "antd";

const getTime = timestamp => {
  const date = new Date(parseFloat(timestamp));
  const m = mtzon.utc(date);
  return m
    .clone()
    .tz("America/New_York")
    .format("MMM Do YYYY, hh:mm A");
};

const PostDetails = ({ data, navBarHeight, close }) => {
  return (
    <div
      className="signal-details-wrapper"
      style={{ height: `calc(100vh - ${navBarHeight}px)` }}
    >
      <div className="author-details">
        <img className="user-avatar" src={data.author.profile_image} alt="" />
        <div className="author-wrapper">
          <h5 className="author-name">{data.author.name}</h5>
          <h6 className="signal-date">Posted on {getTime(data.timestamp)}</h6>
        </div>
        <Button onClick={close} style={{ alignSelf: "center" }}>
          Close
        </Button>
      </div>
      <div className="signal-content">
        <h4 className="signal-header">{data.title}</h4>
        <p className="signal-body">{ReactHtmlParser(data.body)}</p>
      </div>
    </div>
  );
};

export default PostDetails;
