import React from "react";
import PulsePosts from "./PulsePosts";

const Pulse = ({ navBarHeight, opeFloorId, openChannel }) => {
  return (
    <>
      <div className="trade-floor-home h-100">
        <PulsePosts
          activeFloorId={opeFloorId}
          navBarHeight={navBarHeight}
          openChannel={openChannel}
        />
      </div>
    </>
  );
};

export default Pulse;
