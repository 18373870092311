import React from "react";

const CardItem = ({ data }) => {
  return (
    <div className="col-12">
      <div className="product-card">
        <div className="title-wrapper">
          <div className="img-wrapper">
            <img src={data.image} alt="" />
          </div>
          <label className="main-title">{data.title}</label>
        </div>
        <div className="intro-wrapper">
          <label className="sub-title">
            Application: <span>{data.application}</span>
          </label>
          <p className="intro">{data.intro}</p>
          <label className="type">
            Stating Requirement: <span>{data.requirement}</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default CardItem;
