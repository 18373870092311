import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import classRoomLogo from '../assets/images/classroom-white-logo.png';
import '../styles/login-page.scss';
import { emailValidator } from '../utils';
import LocalStorageHelper from '../utils/LocalStorageHelper';

const LoginPage = () => {
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const loginHandler = e => {
    e.preventDefault();

    if (!emailValidator(emailInput)) {
      return toast.error('Please input a valid email id');
    }
    if (!passwordInput) {
      return toast.error('Please input your password');
    }
    setIsLoading(true);

    Axios.post('https://gxauth.apimachine.com/gx/user/login', {
      email: emailInput,
      password: passwordInput
    })
      .then(response => {
        const { data } = response;
        if (data.status) {
          toast.success('✅ Logged in...');
          LocalStorageHelper.setLoginData(
            data.idToken,
            data.accessToken,
            emailInput
          );
          history.push('/');
        } else {
          toast.error(`❌ ${data.message}`);
        }
      })
      .catch(error => {
        console.log('Login Error', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="login-page-wrapper">
      <div className="signup-wrapper">
        <div className="logo-wrapper">
          <img src={classRoomLogo} alt="" className="logo" />
        </div>
        <div className="signup-container">
          <h1 className="app-name">Classrooms</h1>
          <h6 className="app-desc">
            Reinvent The Way Your Learn. Recreate Your Future.
          </h6>
          <div className="signup-btn">GET STARTED FOR FREE</div>
        </div>
      </div>
      <div className="login-wrapper">
        <h1 className="login-header">Welcome Back</h1>
        <h6 className="login-sub-heading">
          Sign In To Get Access To Your Classrooms
        </h6>
        <form className="login-form" onSubmit={loginHandler}>
          <input
            className="login-input"
            type="text"
            placeholder="Email ID"
            value={emailInput}
            onChange={e => setEmailInput(e.target.value)}
          />
          <input
            className="login-input"
            type="password"
            placeholder="Password"
            value={passwordInput}
            onChange={e => setPasswordInput(e.target.value)}
          />
          <button className="login-btn" type="submit">
            LOGIN
            {isLoading && (
              <div
                className="spinner-border spinner-border-sm text-dark ml-3"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
