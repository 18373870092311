import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import { ArrowBack, Check } from "@material-ui/icons";
import { monthList, monthListFull } from "./calenderUtils";
import moment from "moment";

const MonthView = ({
  setShowMonthCalender,
  calenderSelectedMonth,
  calenderSelectedYear,
  setCalenderSelectedMonth,
  setCalenderSelectedYear
}) => {
  const [isModified, setIsModified] = useState(false);

  return (
    <div className="calender-display-wrapper h-100">
      <div className="calender-header">
        <IconButton onClick={() => setShowMonthCalender(false)}>
          {isModified ? <Check /> : <ArrowBack />}
        </IconButton>
      </div>
      <div className="row month-view-row mx-0">
        <div className="month-list">
          {monthList.map((item, index) => (
            <div
              key={item}
              className={`month-wrapper ${
                calenderSelectedMonth.includes(item) ? "active" : ""
              }`}
              onClick={() => {
                setCalenderSelectedMonth(monthListFull[index]);
                setIsModified(true);
              }}
            >
              <h4 className="month-value" key={item}>
                {item}
              </h4>
            </div>
          ))}
        </div>
        <div className="year-list">
          {Array(6)
            .fill(1)
            .map((item, index) => (
              <div
                key={index}
                className={`year-wrapper ${
                  calenderSelectedYear === moment().year() - index
                    ? "active"
                    : ""
                }`}
                onClick={() => {
                  setCalenderSelectedYear(moment().year() - index);
                  setIsModified(true);
                }}
              >
                <h3 className="year-value">{moment().year() - index}</h3>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MonthView;
