import React, { useState, useEffect, useContext } from 'react';
import { Dropdown, Button, Menu } from 'antd';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { AppContext } from '../contexts/AppContext';

const GET_TRADING_FLOORS = gql`
  query($email: ID!) {
    get_my_trading_floor_list(data: { email: $email }) {
      id
      name
      description
      price
    }
  }
`;

const TradingFloorSelector = ({ active, tradingFloorMain, callBack }) => {
  const { setTradingFloorView } = useContext(AppContext);

  const [menuData, setMenuItems] = useState([
    { name: 'Retrieving', id: 'none' }
  ]);
  const [activeSelection, setActiveSelection] = useState(
    active ? active : menuData[0]
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuClick = e => {
    if (e.key !== 'none') {
      setActiveSelection(menuData.find(x => x.id === e.key));
      callBack(menuData.find(x => x.id === e.key));
    }
  };

  const getTradingFloorList = useQuery(GET_TRADING_FLOORS, {
    variables: {
      email: localStorage.getItem('user_account')
    }
  });

  useEffect(() => {
    if (getTradingFloorList.loading) {
      setActiveSelection({ name: 'Retrieving', id: 'none' });
    } else if (getTradingFloorList.data.get_my_trading_floor_list) {
      const menus = getTradingFloorList.data.get_my_trading_floor_list.map(
        item => ({ name: item.name, id: item.id })
      );
      if (menus.length > 0) {
        setMenuItems([...menus]);
        setActiveSelection(active || { name: 'Your Classrooms', id: 'none' });
      } else {
        setMenuItems([
          {
            name: "You haven't subscribed to any Classrooms",
            id: 'none'
          }
        ]);
        setActiveSelection({
          name: "You haven't subscribed to any Classrooms",
          id: 'none'
        });
      }
    }
  }, [getTradingFloorList.data]);

  const menuItems = (
    <Menu onClick={handleMenuClick}>
      {menuData.map(item => (
        <Menu.Item
          key={item.id}
          onClick={e => {
            localStorage.setItem('floor_name', item.name);
            setTradingFloorView(true);
          }}
        >
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  const toggleVisible = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div
      className={`select-broker-wrapper ${
        tradingFloorMain ? 'select-broker-wrapper-main' : ''
      }`}
    >
      <Dropdown
        overlay={menuItems}
        visible={isMenuOpen}
        onVisibleChange={toggleVisible}
        trigger={['click']}
      >
        <Button onClick={toggleVisible}>
          {activeSelection.name}{' '}
          <i className="fas selection-icon fa-angle-down" />
        </Button>
      </Dropdown>
    </div>
  );
};

export default TradingFloorSelector;
