import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import Routes from './Routes';
import { AppProvider } from './contexts/AppContext';
import LocalStorageHelper from './utils/LocalStorageHelper';

const client = new ApolloClient({
  uri: 'https://pulseapi.globalxchange.io/graphql',
  request: operation => {
    const token = LocalStorageHelper.getAppToken();
    const email = LocalStorageHelper.getUserEmail();
    operation.setContext({
      headers: {
        token: token || '',
        email: email || ''
      }
    });
  }
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <AppProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </AppProvider>
    </ApolloProvider>
  );
};

export default App;
