import React, { useState } from "react";
import "./calender.scss";
import CalenderSideBar from "./CalenderSideBar";
import DayView from "./DayView";
import MonthView from "./MonthView";
import {
  getDay,
  getMonth,
  getYear,
  getNextMonthDate,
  getPrevMonthDate,
  getDaysInMonth
} from "./calenderUtils";
import moment from "moment-timezone";

const Calender = ({ navBarHeight }) => {
  const currentDate = moment();

  const [calenderSelectedDay, setCalenderSelectedDay] = useState(
    getDay(currentDate)
  );
  const [calenderSelectedMonth, setCalenderSelectedMonth] = useState(
    getMonth(currentDate)
  );
  const [calenderSelectedYear, setCalenderSelectedYear] = useState(
    getYear(currentDate)
  );
  const [showMonthCalender, setShowMonthCalender] = useState(false);

  const switchToNextMonth = day => {
    const date = getNextMonthDate(
      day,
      calenderSelectedMonth,
      calenderSelectedYear
    );

    setCalenderSelectedDay(date.day);
    setCalenderSelectedMonth(date.month);
    setCalenderSelectedYear(date.year);
  };

  const switchToToPrevMonth = day => {
    const date = getPrevMonthDate(
      day,
      calenderSelectedMonth,
      calenderSelectedYear
    );

    setCalenderSelectedDay(date.day);
    setCalenderSelectedMonth(date.month);
    setCalenderSelectedYear(date.year);
  };

  return (
    <div
      className="calender-wrapper"
      style={{ height: `calc(100vh - ${navBarHeight}px)` }}
    >
      <div className="calender-container h-100">
        <div className="row h-100 mx-0">
          {showMonthCalender ? (
            <MonthView
              setShowMonthCalender={setShowMonthCalender}
              calenderSelectedMonth={calenderSelectedMonth}
              calenderSelectedYear={calenderSelectedYear}
              setCalenderSelectedMonth={setCalenderSelectedMonth}
              setCalenderSelectedYear={setCalenderSelectedYear}
            />
          ) : (
            <DayView
              setCalenderSelectedDay={setCalenderSelectedDay}
              calenderSelectedDay={calenderSelectedDay}
              calenderSelectedMonth={calenderSelectedMonth}
              calenderSelectedYear={calenderSelectedYear}
              setShowMonthCalender={setShowMonthCalender}
              switchToNextMonth={switchToNextMonth}
              switchToToPrevMonth={switchToToPrevMonth}
            />
          )}
          <CalenderSideBar
            setCalenderSelectedDay={setCalenderSelectedDay}
            calenderSelectedDay={calenderSelectedDay}
            calenderSelectedMonth={calenderSelectedMonth}
            calenderSelectedYear={calenderSelectedYear}
            noOfDaysInMonth={getDaysInMonth(
              calenderSelectedMonth,
              calenderSelectedYear
            )}
            switchToNextMonth={switchToNextMonth}
            switchToToPrevMonth={switchToToPrevMonth}
          />
        </div>
      </div>
    </div>
  );
};

export default Calender;
