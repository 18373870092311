export default class LocalStorageHelper {
  static setLoginData = (token, accessToken, email) => {
    localStorage.setItem('classroomToken', token);
    localStorage.setItem('classroomAccessToken', accessToken);
    localStorage.setItem('classroomLoginAccount', email);
  };

  static getAppToken = () => {
    return localStorage.getItem('classroomToken');
  };

  static getUserEmail = () => {
    return localStorage.getItem('classroomLoginAccount');
  };

  static clearLoginData = () => {
    localStorage.getItem('classroomToken');
    localStorage.getItem('classroomAccessToken');
    localStorage.getItem('classroomLoginAccount');
  };
}
