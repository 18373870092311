import React from "react";
import {
  weeksList,
  getDaysInMonth,
  getStartingPosOfMonth,
  getDaysInPrevMonth
} from "./calenderUtils";

const DayView = ({
  calenderSelectedDay,
  calenderSelectedMonth,
  calenderSelectedYear,
  setCalenderSelectedDay,
  setShowMonthCalender,
  switchToNextMonth,
  switchToToPrevMonth
}) => {
  const totalDaysInThisMonth = getDaysInMonth(
    calenderSelectedMonth,
    calenderSelectedYear
  );

  const startingPosOfMonth = getStartingPosOfMonth(
    calenderSelectedMonth,
    calenderSelectedYear
  );

  const totalDaysInPrevMonth = getDaysInPrevMonth(
    calenderSelectedMonth,
    calenderSelectedYear
  );

  const arrDaysCurrentMonth = Array(totalDaysInThisMonth)
    .fill()
    .map((e, i) => i + 1);

  let arrDaysPrevMoths = [];
  for (
    let i = totalDaysInPrevMonth - startingPosOfMonth + 1;
    i <= totalDaysInPrevMonth;
    i++
  ) {
    arrDaysPrevMoths = [...arrDaysPrevMoths, i];
  }

  let calenderDays = [...arrDaysPrevMoths, ...arrDaysCurrentMonth];

  const arrDaysNextMonth = Array(42 - calenderDays.length)
    .fill()
    .map((e, i) => i + 1);

  calenderDays = [...calenderDays, ...arrDaysNextMonth];

  return (
    <div className="calender-display-wrapper h-100">
      <div
        className="calender-details"
        onClick={() => setShowMonthCalender(true)}
      >
        <h3 className="text-center month">{calenderSelectedMonth}</h3>
        <h6 className="text-center year">{calenderSelectedYear}</h6>
      </div>
      <div className="calender-view">
        {weeksList.map(item => (
          <h5 key={item} className="weeks">
            {item}
          </h5>
        ))}
        {calenderDays.map((item, index) => (
          <div
            key={index}
            className={`day-wrapper ${
              (item >= 20 && index < 6) || (item < 14 && index > 20)
                ? "prev-day-wrapper"
                : calenderSelectedDay === item
                ? "active"
                : ""
            }`}
            onClick={() =>
              item >= 20 && index < 6
                ? switchToToPrevMonth(item)
                : item < 14 && index > 20
                ? switchToNextMonth(item)
                : setCalenderSelectedDay(item)
            }
          >
            <h5 className="day-value">{item}</h5>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DayView;
