import React from "react";

const GlobalNews = () => {
  return (
    <div className="trade-global-news">
      <h4 className="global-news-title">Global News Stream</h4>
      <p className="intro-text">
        We stream industry related news in real time from trusted sources...
      </p>
      <div className="feeds-container">
        <FeedItem />
        <FeedItem />
        <FeedItem />
        <FeedItem />
        <FeedItem />
        <FeedItem />
        <FeedItem />
        <FeedItem />
      </div>
    </div>
  );
};

const FeedItem = () => {
  return (
    <div className="feed-wrapper">
      <img src="https://i.pravatar.cc/110" alt="" className="feed-img" />
      <div className="feed-contents-wrapper">
        <p className="feed-content">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quisquam
          adipisci aut similique nihil ad qui velit explicabo alias quis id
          beatae sit ratione aliquid in, animi perferendis officiis aliquam
          hic!Deleniti unde eos expedita fugiat ut dolore at ipsum temporibus ad
          corrupti, quisquam.
        </p>
        <div className="feed-meta">
          <label className="feed-username">@z3r0c00l-2k</label>
          <label className="feed-seen">
            <span>
              <i class="fa fa-users" aria-hidden="true"></i>
            </span>
            232453
          </label>
          <label className="feed-time">
            <span>
              <i class="fa fa-clock-o" aria-hidden="true"></i>
            </span>
            03 mins ago
          </label>
        </div>
      </div>
    </div>
  );
};

export default GlobalNews;
