import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import Axios from 'axios';
import { IconButton, Icon } from '@material-ui/core';
import PlayerOverview from './PlayerOverview';
import Lessons from './Lessons';
import Discussion from './Discussion';
import './course-player.scss';
import RelatedVideos from './RelatedVideos';

const CoursePlayer = ({
  data,
  isOpen,
  closePlayer,
  navBarHeight,
  close,
  closable,
  tabs
}) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [activeVideo, setActiveVideo] = useState('');
  const [tabHeight, setTabHeight] = useState(0);

  const tabsRef = useRef();

  useEffect(() => {
    tabsHeightListener();
    window.addEventListener('resize', tabsHeightListener);
    return () => {
      window.removeEventListener('resize', tabsHeightListener);
    };
  }, []);

  useEffect(() => {
    if (data.chapters) {
      getVideoStreamLink(data.chapters[data.index ? data.index : 0].id);
    }
    return () => {};
  }, [data]);

  const tabsHeightListener = () => {
    setTabHeight(tabsRef.current.clientHeight);
  };

  const getVideoStreamLink = videoId => {
    Axios.post(
      'https://vod-backend.globalxchange.io/get_stream_link_trading_floor',
      {
        email: localStorage.getItem('user_account'),
        id: videoId
      }
    )
      .then(resp => {
        const url = encodeURI(resp.data);
        console.log('Video URL => ', url);
        setActiveVideo(url);
      })
      .catch(error => {
        console.log('Error from getVideoStreamLink', error);
      });
  };

  let tabContent;
  switch (activeTab) {
    case 'Overview':
      tabContent = (
        <PlayerOverview data={data} close={close} closable={closable} />
      );
      break;
    case 'Lessons':
      tabContent = <Lessons data={data} setActiveVideo={getVideoStreamLink} />;
      break;
    case 'Discussions':
      tabContent = <Discussion data={data} />;
      break;
    case 'Related':
      tabContent = <RelatedVideos data={data} />;
      break;
    default:
      tabContent = <Discussion data={data} />;
  }

  return (
    <div
      className="course-player-wrapper"
      style={{
        top: navBarHeight,
        height: `calc(100vh - ${navBarHeight}px)`
      }}
    >
      <ReactPlayer url={activeVideo} width={'100%'} height={'50%'} controls />
      <div className="player-tabs-wrapper" ref={tabsRef}>
        {tabs.map((item, index) => (
          <div
            key={index}
            className={`player-tab ${activeTab === item ? 'active' : ''}`}
            onClick={() => setActiveTab(item)}
          >
            {item}
          </div>
        ))}
        {closable ? (
          <IconButton aria-label="close" onClick={close}>
            <Icon className="fas fa-times-circle" />
          </IconButton>
        ) : null}
      </div>
      <div
        className="player-tab-content-wrapper"
        style={{ height: `calc(50% - ${tabHeight}px)` }}
      >
        <div className="player-tab-content">{tabContent}</div>
      </div>
    </div>
  );
};

export default CoursePlayer;
