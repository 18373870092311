import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Fab } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { getWeekName } from "./calenderUtils";

const getNumberWithOrdinal = n => {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return s[(v - 20) % 10] || s[v] || s[0];
};

const CalenderSideBar = ({
  calenderSelectedDay,
  calenderSelectedMonth,
  calenderSelectedYear,
  setCalenderSelectedDay,
  noOfDaysInMonth,
  switchToNextMonth,
  switchToToPrevMonth
}) => {
  return (
    <div className="activity-display-wrapper h-100">
      <div className="day-carousel">
        <Carousel
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          centerMode
          centerSlidePercentage={50}
          infiniteLoop
          selectedItem={calenderSelectedDay - 1}
          onChange={x => setCalenderSelectedDay(x + 1)}
        >
          {Array(noOfDaysInMonth)
            .fill(1)
            .map((item, index) => (
              <div
                key={index}
                className="calender-carousel-item"
                onClick={() => {
                  setCalenderSelectedDay(index + 1);
                }}
              >
                <h5 className="day-of-week">
                  {getWeekName(
                    calenderSelectedDay,
                    calenderSelectedMonth,
                    calenderSelectedYear
                  )}
                </h5>
                <h1 className="date-of-week">
                  {index + 1}
                  <sup>{getNumberWithOrdinal(index + 1)}</sup>
                </h1>
              </div>
            ))}
        </Carousel>
      </div>
      <div className="activities-wrapper">
        <h5 className="brokerage-header">
          Brokerage Summary{" "}
          <span>
            <i class="fa fa-angle-right"></i>
          </span>
        </h5>
        <div className="activity-list">
          <div className="activity-item">
            <h6 className="activity-title">Brokerage Earning To Date</h6>
            <h4 className="activity-value">$13,122,49</h4>
          </div>
          <div className="activity-item">
            <h6 className="activity-title">Total Withdrawn To Date</h6>
            <h4 className="activity-value">$13,122,49</h4>
          </div>
          <div className="activity-item">
            <h6 className="activity-title">Brokerage Balance</h6>
            <h4 className="activity-value">$13,122,49</h4>
          </div>
        </div>
      </div>
      <div className="summary-wrapper">
        <div className="withdrawal-balance-wrapper">
          <label className="withdrawal-balance-title">Withdrawal Balance</label>
          <h5 className="withdrawal-balance-value">$11,122,49</h5>
        </div>
        <div className="fab-wrapper">
          <Fab color="primary" aria-label="add">
            <Add />
          </Fab>
        </div>
      </div>
    </div>
  );
};

export default CalenderSideBar;
