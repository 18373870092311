import React, { useState, useEffect } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import Lottie from 'react-lottie';
import CourseItem from './CourseItem';
import CoursePlayer from '../../CoursePlayer/CoursePlayer';
import gxlogo from '../../../assets/images/gxok.png';
import * as notfoundAnimation from './channel-not-found.json';
import LoadingAnimation from '../../LoadingAnimation/LoadingAnimation';

const GET_TRADING_FLOOR_COURSE_POST = gql`
  query($email: ID!, $tier_id: ID!) {
    get_trading_floor_course_posts(data: { email: $email, tier_id: $tier_id }) {
      id
      name
      description
      creator_pulse_id
      cover_url
      author {
        name
        profile_image
      }
      chapters {
        id
        name
        title
        description
        view_count
      }
    }
  }
`;

const Channel = ({ navBarHeight, floorId, payload }) => {
  const courseCategories = ['Category 1', 'Category 2', 'Category 3'];
  const [courses, setCourses] = useState([]);
  const [activeCourse, setActiveCourse] = useState(
    payload || (courses ? courses[0] : {})
  );
  const [activeCategory, setActiveCategory] = useState(courseCategories[0]);
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);

  const courseClickHandler = course => {
    setActiveCourse({ ...course, index: 0 });
    setIsPlayerOpen(true);
  };

  const getCoursePosts = useQuery(GET_TRADING_FLOOR_COURSE_POST, {
    variables: {
      email: localStorage.getItem('user_account'),
      tier_id: floorId
    }
  });

  useEffect(() => {
    if (!getCoursePosts.loading) {
      if (!getCoursePosts.error && getCoursePosts.data) {
        setCourses(getCoursePosts.data.get_trading_floor_course_posts);
      } else {
        setCourses(null);
      }
    }
    return () => {};
  }, [getCoursePosts]);

  useEffect(() => {
    if (courses) {
      setActiveCourse(payload || { ...courses[0], index: 0 });
    }
    return () => {};
  }, [courses]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notfoundAnimation.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      <div className="trade-training-lab h-100">
        <div className="row h-100 mx-0">
          {courses ? (
            courses.length > 0 ? (
              <>
                <div className="col-md-2 h-100 categories-col">
                  <h5
                    className="training-lab-header category-header"
                    style={{ paddingBottom: '20px' }}
                  >
                    Categories
                  </h5>
                  <ul className="course-categories">
                    {courseCategories.map(item => (
                      <li
                        key={item}
                        className={`category-item ${
                          item === activeCategory ? 'active' : ''
                        }`}
                        onClick={() => setActiveCategory(item)}
                      >
                        <img className="category-icon" src={gxlogo} alt="" />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-md-3 h-100 courses-col">
                  <h5
                    className="training-lab-header active-category-header"
                    style={{ paddingBottom: '20px' }}
                  >
                    <img className="active-category-icon" src={gxlogo} alt="" />
                    {activeCategory}
                  </h5>
                  <div className="training-lab-item-wrapper">
                    {courses
                      ? courses.map((item, index) => (
                          <CourseItem
                            item={item}
                            key={index}
                            onClick={courseClickHandler}
                          />
                        ))
                      : ''}
                  </div>
                </div>
                <div className="col-md-7 px-0 h-100">
                  <CoursePlayer
                    data={activeCourse}
                    isOpen={isPlayerOpen}
                    setOpen={setIsPlayerOpen}
                    navBarHeight={navBarHeight}
                    tabs={['Overview', 'Lessons', 'Discussions']}
                  />
                </div>
              </>
            ) : (
              <LoadingAnimation />
            )
          ) : (
            <div className="empty-container mx-auto mt-5">
              <Lottie options={defaultOptions} height="200px" width="200px" />
              <h4 className="text-center">Could not find any channels...</h4>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Channel;
