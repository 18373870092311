import React, { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { AppContext } from '../contexts/AppContext';
import LocalStorageHelper from '../utils/LocalStorageHelper';
import { gxApiEndPoint } from '../configs';

const AppLayoutWrapper = ({ children }) => {
  const { setIsLoggedIn } = useContext(AppContext);

  useEffect(() => {
    toast.configure();

    const token = LocalStorageHelper.getAppToken();

    if (token) {
      Axios.post(`${gxApiEndPoint}brokerage/verify_token`, {
        token,
        userpoolid: 'us-east-2_ALARK1RAa'
      })
        .then(resp => {
          let user;
          if (resp.data) user = resp.data;
          else user = null;

          if (user !== null && Date.now() < user.exp * 1000) {
            // console.log('Session valid!!');
            setIsLoggedIn(true);
          } else {
            // console.log('Invalid Session', resp);
            LocalStorageHelper.setLoginData('', '', '');
            setIsLoggedIn(false);
          }
        })
        .catch(error => {
          // console.log('Token verify Error', error);
          setIsLoggedIn(false);
        });
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  return <>{children}</>;
};

export default AppLayoutWrapper;
