import React, { useState, useEffect } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import AcademyPost from './PostCards/AcademyPost';
import SignalPost from './PostCards/SignalPost';
import CoursePlayer from '../../CoursePlayer/CoursePlayer';
import PostDetails from './PostDetails';
import LoadingAnimation from '../../LoadingAnimation/LoadingAnimation';

const GET_TRADING_FLOOR_COURSE_POST = gql`
  query($email: ID!, $tier_id: ID!) {
    get_trading_floor_course_posts(data: { email: $email, tier_id: $tier_id }) {
      id
      name
      description
      creator_pulse_id
      cover_url
      author {
        name
        profile_image
      }
      chapters {
        id
        name
        title
        description
        view_count
      }
    }
  }
`;

const GET_TRADING_FLOOR_POSTS = gql`
  query($email: ID!, $tier_id: ID!) {
    get_trading_floor_posts(data: { email: $email, tier_id: $tier_id }) {
      id
      title
      body
      like_count
      comment_count
      timestamp
      author {
        name
        profile_image
      }
    }
  }
`;

const PulsePosts = ({ activeFloorId, navBarHeight, openChannel }) => {
  const [posts, setPosts] = useState([]);
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);
  const [playerData, setPlayerData] = useState(null);
  const [isPostOpen, setIsPostOpen] = useState(false);
  const [postData, setPostData] = useState(null);

  const getCoursePosts = useQuery(GET_TRADING_FLOOR_COURSE_POST, {
    variables: {
      email: localStorage.getItem('user_account'),
      tier_id: activeFloorId
    }
  });
  const getPost = useQuery(GET_TRADING_FLOOR_POSTS, {
    variables: {
      email: localStorage.getItem('user_account'),
      tier_id: activeFloorId
    }
  });

  const openCoursePLayer = (data, index) => {
    setIsPostOpen(false);
    setIsPlayerOpen(true);
    setPlayerData({ ...data, index: index });
  };

  const openPulsePost = data => {
    setIsPlayerOpen(false);
    setPostData(data);
    setIsPostOpen(true);
  };

  const navigateToChannel = data => {
    openChannel(data);
  };

  useEffect(() => {
    console.log('GetPOST => ', getPost, 'GetCoursePost => ', getCoursePosts);
    setPosts([]);
    let normalPosts = [];
    let coursePosts = [];

    normalPosts = !getPost.error
      ? getPost.data
        ? getPost.data.get_trading_floor_posts.map(item => ({
            ...item,
            type: 'normal'
          }))
        : []
      : [];

    coursePosts = !getCoursePosts.error
      ? getCoursePosts.data
        ? getCoursePosts.data.get_trading_floor_course_posts.map(item => ({
            ...item,
            type: 'course'
          }))
        : []
      : [];

    setPosts([...normalPosts, ...coursePosts]);

    return () => {};
  }, [getCoursePosts, getPost]);

  return (
    <>
      {posts.length > 0 ? (
        <div className="row stream-row h-100 mx-0">
          <div className="home-contents h-100">
            {posts.map(item => {
              if (item.type === 'course')
                return (
                  <AcademyPost
                    key={item.id}
                    data={item}
                    openPlayer={openCoursePLayer}
                    navigateToChannel={navigateToChannel}
                  />
                );
              if (item.type === 'normal')
                return (
                  <SignalPost
                    key={item.id}
                    data={item}
                    onClickHandler={() => openPulsePost(item)}
                  />
                );
              return '';
            })}
          </div>
          <div className="player-wrapper h-100">
            {isPlayerOpen && (
              <CoursePlayer
                closePlayer={() => setIsPlayerOpen(false)}
                data={playerData}
                navBarHeight={navBarHeight}
                close={() => setIsPlayerOpen(false)}
                closable
                tabs={['Overview', 'Related', 'Comments']}
              />
            )}
            {isPostOpen && (
              <PostDetails
                data={postData}
                navBarHeight={navBarHeight}
                close={() => setIsPostOpen(false)}
              />
            )}
          </div>
        </div>
      ) : (
        <LoadingAnimation />
      )}
    </>
  );
};

export default PulsePosts;
