import React from "react";

const ManagedFunds = () => {
  return (
    <div className="trade-manged-funds">
      <h3 className="text-center mt-5">Coming Soon...</h3>
    </div>
  );
};

export default ManagedFunds;
