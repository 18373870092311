import React from "react";
import { Button } from "antd";

const AcademyPost = ({ data, openPlayer, navigateToChannel }) => {
  return (
    <div className="home-post-card home-post-card-academy">
      <div className="bar"></div>
      <div className="card-contents">
        <p className="sub-title">{data.description}</p>
        <div className="progress-wrapper">
          <label className="progress-title">Track Your Progress</label>
          <div className="progress-container">
            <div className="progress">
              <div style={{ width: "10%" }} />
            </div>
            <span className="progress-text">10%</span>
          </div>
        </div>
        <div className="video-list">
          {data.chapters.map((item, index) => (
            <VideoItem
              key={index}
              data={item}
              onClick={() => openPlayer(data, index)}
            />
          ))}
        </div>
        <Button type="primary" block onClick={() => navigateToChannel(data)}>
          Let's get started!
        </Button>
      </div>
    </div>
  );
};

const VideoItem = ({ data, onClick }) => {
  return (
    <div className="video-item-container" onClick={onClick}>
      <i class="fa fa-youtube-play" aria-hidden="true"></i>
      <label className="video-title">{data.title}</label>
      <i class="fa fa-check float-right" aria-hidden="true"></i>
    </div>
  );
};

export default AcademyPost;
