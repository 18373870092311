import React from 'react';
import bg from '../../assets/images/blurry.jpeg';

const Lessons = ({ setActiveVideo, data }) => {
  console.log('Lesson Data', data);
  return data ? (
    <div className="lessons-wrapper">
      {data.chapters.map((item, index) => (
        <div
          key={index}
          className="lesson-item"
          onClick={() => setActiveVideo(item.id)}
        >
          <div className="thumbnail-wrapper">
            <img src={bg} alt="" className="thumbnail" />
          </div>
          <div className="lesson-details">
            <label className="lesson-number">{`Lesson ${index + 1}`}</label>
            <label className="lesson-title">{item.title}</label>
          </div>
        </div>
      ))}
    </div>
  ) : (
    ''
  );
};

export default Lessons;
