import React from "react";
import ReactHtmlParser from "react-html-parser";
import mtzon from "moment-timezone";

export const getTime = timestamp => {
  const date = new Date(parseFloat(timestamp));
  const m = mtzon.utc(date);
  return m
    .clone()
    .tz("America/New_York")
    .format("MMM Do YYYY, hh:mm A");
};

const SignalPost = ({ onClickHandler, data }) => {
  return (
    <div className="home-post-card home-post-card-signal">
      <div className="bar"></div>
      <div className="card-contents" onClick={onClickHandler}>
        <div className="signal-meta">
          <img className="user-avatar" src={data.author.profile_image} alt="" />
          <h6 className="user-name">{data.author.name}</h6>
        </div>
        <label className="asset-class">{data.title}</label>
        <p className="intro-text">
          {data.body.length > 50
            ? `${ReactHtmlParser(data.body.substring(0, 50))} ...`
            : ReactHtmlParser(data.body)}
        </p>
      </div>
    </div>
  );
};

export default SignalPost;
