import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/FontIcons/style.css';

const ClassroomNav = ({ active, setActive, navLinks }) => {
  console.log('Nav Links ', navLinks);

  return (
    <nav className="affiliate-nav">
      <div className="nav-container">
        <ul className="nav-menu">
          {navLinks.map((item, index) => (
            <li
              className={`${item.enabled ? '' : 'disabled'}`}
              key={index}
              onClick={
                item.enabled
                  ? item.subMenu
                    ? null
                    : () => setActive(item.title)
                  : null
              }
            >
              <Link
                to={item.link}
                className={active.includes(item.title) ? 'active' : ''}
              >
                <i class={item.icon} aria-hidden="true" />
                <span>{item.title}</span>
              </Link>
              {item.subMenu ? (
                <ul className="sub-menu">
                  {item.subMenu.map((el, index) => (
                    <li
                      className="sub-menu-item"
                      key={index}
                      onClick={() =>
                        item.enabled ? setActive(`${el},${item.title}`) : null
                      }
                    >
                      <a className={active.includes(el) ? 'active' : ''}>
                        {el}
                      </a>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default ClassroomNav;
