import React, { useState, useEffect, useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import backgroundImageOne from '../assets/images/trading-landing-bg-1.jpg';
import backgroundImageTwo from '../assets/images/trading-landing-bg-2.jpg';
import backgroundImageThree from '../assets/images/trading-landing-bg-3.jpg';
import classRoomLogo from '../assets/images/classroom-white-logo.png';
import classRoomBlueLogo from '../assets/images/classroom-blue-logo.png';
import AppLayoutWrapper from '../layout/AppLayoutWrapper';
import { AppContext } from '../contexts/AppContext';
import LocalStorageHelper from '../utils/LocalStorageHelper';
import TradingFloorSelector from '../components/TradingFloorSelector';

const carouselData = [
  {
    title: 'Lorem Inpsum',
    heading: 'Welcome To The Classroom',
    intro:
      'A Virtual Learning Experience Where You Get To Intereact WIth Your Favourite Pulse.Streams In A Completely New Way',
    background: backgroundImageOne
  },
  {
    title: 'Nunc Sed',
    heading: 'Get Exclusive Access',
    intro:
      'Your Pulser Has Currated Their Best Content Into A Content Library For You To Absorb Over Multiple Courses',
    background: backgroundImageTwo
  },
  {
    title: 'Et Tortor',
    heading: '24/7 Access',
    intro:
      'Use In Built Channels To Chat, Stream, And Interact With Your Pulser And All The Others In This Classroom',
    background: backgroundImageThree
  }
];

const LandingPage = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AppContext);

  const [carouselPos, setCarouselPos] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);

  const screenResizeListener = () => {
    setIsMobile(window.innerWidth <= 500);
  };

  const logoutHandler = () => {
    setIsLoggedIn(false);
    LocalStorageHelper.clearLoginData();
  };

  useEffect(() => {
    screenResizeListener();
    window.addEventListener('resize', screenResizeListener);
    return () => {
      window.removeEventListener('resize', screenResizeListener);
    };
  }, []);

  return (
    <AppLayoutWrapper>
      <div
        className="trading-floor-landing"
        style={{
          backgroundImage: `url(${carouselData[carouselPos].background})`
        }}
      >
        <nav className="trading-landing-nav">
          <div className="go-back-wrapper">
            {isLoggedIn ? (
              <Button onClick={logoutHandler}>Logout</Button>
            ) : (
              <Link to="/login">
                <Button>Login</Button>
              </Link>
            )}
          </div>
          <TradingFloorSelector />
        </nav>
        <div className="trading-floor-branding">
          <img
            className="branding-logo"
            src={isMobile ? classRoomBlueLogo : classRoomLogo}
            alt="Classrooms"
          />
        </div>
        <div className="floor-landing-content">
          <div className="container">
            <Carousel
              showThumbs={false}
              showStatus={false}
              showIndicators={false}
              showArrows={!isMobile}
              autoPlay
              interval={5000}
              infiniteLoop
              swipeable={isMobile}
              selectedItem={carouselPos}
              onChange={pos => setCarouselPos(pos)}
            >
              {carouselData.map((item, index) => (
                <div key={index} className="floor-slider-wrapper">
                  <div className="slider-contents">
                    <h1 className="slider-header">{item.heading}</h1>
                    <p className="intro">{item.intro}</p>
                    <div
                      className="featured-image"
                      style={{
                        backgroundImage: `url(${item.background})`
                      }}
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </AppLayoutWrapper>
  );
};

export default LandingPage;
