import moment from "moment-timezone";

export const monthList = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC"
];

export const monthListFull = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER"
];

export const weeksList = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const weeksListFull = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

export const getDay = date => {
  return date.date();
};

export const getMonth = date => {
  return monthListFull[date.month()];
};

export const getYear = date => {
  return date.year();
};

export const getWeekName = (day, month, year) => {
  const momentDate = moment(
    `${year}-${monthListFull.findIndex(x => x === month) + 1}-${day}`
  );
  return weeksListFull[momentDate.day()];
};

export const getDaysInMonth = (month, year) => {
  const momentDate = moment(
    `${year}-${monthListFull.findIndex(x => x === month) + 1}-01`
  );

  return momentDate.daysInMonth();
};

export const getDaysInPrevMonth = (month, year) => {
  const momentDate = moment(
    `${year}-${monthListFull.findIndex(x => x === month) + 1}-01`
  ).subtract(1, "months");

  return momentDate.daysInMonth();
};

export const getStartingPosOfMonth = (month, year) => {
  const momentDate = moment(
    `${year}-${monthListFull.findIndex(x => x === month) + 1}-01`
  );

  return momentDate.day();
};

export const getMomentDate = (day, month, year) => {
  return moment(
    `${year}-${monthListFull.findIndex(x => x === month) + 1}-${day}`
  );
};

export const getNextMonthDate = (day, month, year) => {
  const momentDate = moment(
    `${year}-${monthListFull.findIndex(x => x === month) + 1}-${day}`
  ).add(1, "months");

  return {
    day: momentDate.date(),
    month: monthListFull[momentDate.month()],
    year: momentDate.year()
  };
};

export const getPrevMonthDate = (day, month, year) => {
  const momentDate = moment(
    `${year}-${monthListFull.findIndex(x => x === month) + 1}-${day}`
  ).subtract(1, "months");

  return {
    day: momentDate.date(),
    month: monthListFull[momentDate.month()],
    year: momentDate.year()
  };
};
