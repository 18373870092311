import React from "react";
import RelatedVideoItem from "./RelatedVideoItem";

const RelatedVideos = ({ data }) => {
  return (
    <div className="related-videos-wrapper">
      <div className="up-next-item-wrapper">
        <h6>Up next</h6>
        <RelatedVideoItem />
      </div>
      <div className="related-items-wrapper">
        <RelatedVideoItem />
        <RelatedVideoItem />
        <RelatedVideoItem />
        <RelatedVideoItem />
        <RelatedVideoItem />
        <RelatedVideoItem />
        <RelatedVideoItem />
        <RelatedVideoItem />
      </div>
    </div>
  );
};

export default RelatedVideos;
