import React from "react";

const Discussion = () => {
  return (
    <>
      <h4 className="text-center mt-5">Coming Soon..</h4>
    </>
  );
};

export default Discussion;
