import React, { Component, createContext } from 'react';
import LocalStorageHelper from '../utils/LocalStorageHelper';

export const AppContext = createContext();

export class AppProvider extends Component {
  constructor(props) {
    super(props);

    this.state = { isLoggedIn: true };
  }

  componentDidMount() {
    if (LocalStorageHelper.getAppToken()) {
      this.setState({ isLoggedIn: true });
    } else {
      this.setState({ isLoggedIn: false });
    }
  }

  setIsLoggedIn = isLoggedIn => {
    this.setState({ isLoggedIn });
  };

  render() {
    const { children } = this.props;

    return (
      <AppContext.Provider
        value={{ ...this.state, setIsLoggedIn: this.setIsLoggedIn }}
      >
        {children}
      </AppContext.Provider>
    );
  }
}
